import * as React from 'react';

export default function Component() {
  return (
    <div>
      <p>Page not found</p>
      <a href="/">Home Page</a>
    </div>
  );
}
